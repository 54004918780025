import urljoin from 'url-join'
import { API_URL } from '../lib/config'
import axios from 'axios'

const getHeaders = (token) => {
  const headers = {
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  return headers
}

const api = {

  login: async (email) => {
    const data = { email }
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/login'), data, config)
      return {
        status: res.status,
        data: res
      }
    }
    catch (e) {
      console.log('Error logging in', e)
      return null
    }
  },

  token: async (data) => {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/token'), data, config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error token request', e)
      return null
    }
  },

  signup: async (data) => {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/signup'), data, config)
      return {
        status: res.status,
        data: res
      }
    }
    catch (e) {
      console.log('Error signup', e)
      return null
    }
  },

  bankLink: async (webJWT, data) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/bank-link'), data, config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error bank-link', e)
      return null
    }
  },

  verify: async (aggId, webJWT, data) => {
    const endpoints = {
      'finicity': '/verify-fny',
      'mx': '/verify-mx',
      'yodlee': '/verify-yodlee'
    }
    const config = {
      headers: getHeaders(webJWT)
    }
    const verifyEndpoint = endpoints[aggId]
    try {
      const res = await axios.post(
        urljoin(API_URL, verifyEndpoint),
        data,
        config
      )
      return {
        status: res.status,
        data: res
      }
    }
    catch (e) {
      console.log('Error verify', e)
      return null
    }
  },

  verifyReports: async (webJWT, data) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios.post(
        urljoin(API_URL, '/verify-reports'),
        data,
        config
      )
      return {
        status: res.status,
        data: res
      }
    }
    catch (e) {
      console.log('Error verify-reports', e)
      return null
    }
  },

  profile: async (webJWT) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios(urljoin(API_URL, '/user-profile'), config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error signup', e)
      return null
    }
  },

  accounts: async (webJWT) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios(urljoin(API_URL, '/accounts'), config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error signup', e)
      return null
    }
  },

}

export default api
