import './App.css'
import React, {
  createContext,
  useEffect,
  useReducer,
} from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'

import Home from './components/page/home'
import Login from './components/page/login'
import LoadJWT from './components/page/load_jwt'
import Task from './components/page/task'
import TaskCB from './components/page/task_cb'
import Results from './components/page/results'
import MessageModal from './components/modal/message'
import LoadingModal from './components/modal/loading'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  getJWT,
  verifyJWT,
  setJWT,
  removeJWT,
  decodeJWT
} from './util/jwt'
import api from './util/api'
import { CssBaseline } from '@mui/material'

export const AppContext = createContext()

const theme = createTheme({
  // custom theme
  components: {}
})

// const drawerWidth = 240

const initialState = {
  webJWT: null,
  loginJWT: null,
  decodedJWT: null,
  profile: null,
  accounts: null,
  msgModal: null,
  loadingModal: null,
  accsLoading: false,
  profLoading: false,
}

const reducer = (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      newState[action.state] = action.value
      return newState
    case 'show_loading':
      newState['loadingModal'] = <LoadingModal />
      return newState
    case 'close_loading':
      newState['loadingModal'] = null
      return newState
    case 'reset':
      return initialState
    default:
      return newState
  }
}

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getToken = async () => {
    const token = getJWT()
    if (!token) return

    const verify = await verifyJWT(token)
    if (!verify) return
    return token
  }

  const handleLogout = () => {
    dispatch({ type: 'reset' })
    removeJWT()
  }

  const fetchToken = async (loginJWT) => {
    const data = { login_token: loginJWT }
    try {
      const resp = await api.token(data)
      dispatch({
        type: 'set',
        state: 'webJWT',
        value: resp.data
      })
      setJWT(resp.data)
    }
    catch (e) {
      console.log('Error signing up', e)
    }
  }

  const fetchProfile = async (webJWT) => {
    dispatch({
      type: 'set',
      state: 'profLoading',
      value: true
    })
    try {
      const resp = await api.profile(webJWT)
      if (!resp.data) return
      dispatch({
        type: 'set',
        state: 'profile',
        value: resp.data
      })
    }
    catch (e) {
      console.log('Error fetching profile', e)
    }
    finally {
      dispatch({
        type: 'set',
        state: 'profLoading',
        value: false
      })
    }
  }

  const fetchAccounts = async (webJWT) => {
    dispatch({
      type: 'set',
      state: 'accsLoading',
      value: true
    })
    try {
      const resp = await api.accounts(webJWT)
      if (!resp.data) return
      dispatch({
        type: 'set',
        state: 'accounts',
        value: resp.data.accounts
      })
    }
    catch (e) {
      console.log('Error fetching accounts', e)
    }
    finally {
      dispatch({
        type: 'set',
        state: 'accsLoading',
        value: false
      })
    }
  }

  useEffect(() => {
    const init = async () => {
      const token = await getToken()
      if (!token) return
      dispatch({
        type: 'set',
        state: 'webJWT',
        value: token
      })
      dispatch({
        type: 'set',
        state: 'decodedJWT',
        value: decodeJWT(token)
      })
    }
    init()
  }, [])

  useEffect(() => {
    if (state.webJWT) {
      fetchProfile(state.webJWT)
      fetchAccounts(state.webJWT)
    }
  }, [state.webJWT])

  const showAlert = (message) => {
    if (!message) return
    dispatch({
      type: 'set',
      state: 'msgModal',
      value: (
        <MessageModal content={message} />
      )
    })
  }

  const renderAlerts = () => {
    if (state.loadingModal) {
      return state.loadingModal
    }
    if (state.msgModal) {
      return state.msgModal
    }
    return
  }

  const value = {
    state,
    dispatch,
    showAlert,
    handleLogout,
    fetchToken,
    fetchProfile,
    fetchAccounts
  }

  const renderRoutes = () => {
    return (
      <Routes>
        {state.loginJWT || state.webJWT ?
          <>
            <Route path='/' element={<Home />} />
              {state.profile &&
                <>
                  <Route
                    path='/1'
                    element={<Task aggId='finicity' status='task_1' nextPage='/2'/>}
                  />
                  <Route
                    path='/2'
                    element={<Task aggId='mx' status='task_2' nextPage='/3'/>}
                  />
                  <Route
                    path='/3'
                    element={<Task aggId='yodlee' status='task_3' nextPage='/4'/>}
                  />
                  <Route path='/4' element={<TaskCB nextPage='/results'/>} />
                  <Route path='/results' element={<Results />} />
                </>
              }
            <Route path='*' element={<Home />} />
          </> :
          <>
            <Route path='/login' element={<Login />} />
            <Route path='/login/:jwt' element={<LoadJWT />} />
            <Route path='*' element={<Login />} />
          </>
        }
      </Routes>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={value}>
        <CssBaseline />
        {renderRoutes()}
        {renderAlerts()}
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
