import React, { useContext } from 'react'

import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import Dashboard from '@mui/icons-material/Dashboard'
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded'
import RuleRoundedIcon from '@mui/icons-material/RuleRounded'

import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'

const drawerWidth = 260

export default function Sidenav() {
  const navigate = useNavigate()
  const { state } = useContext(AppContext)

  const pages = [
    {
      path: '/',
      displayName: 'Overview and Consent',
      icon: <Dashboard />
    },
    {
      path: '/1',
      displayName: 'Task 1',
      icon: <PendingActionsRoundedIcon />
    },
    {
      path: '/2',
      displayName: 'Task 2',
      icon: <PendingActionsRoundedIcon />
    },
    {
      path: '/3',
      displayName: 'Task 3',
      icon: <PendingActionsRoundedIcon />
    },
    {
      path: '/4',
      displayName: 'Task 4',
      icon: <PendingActionsRoundedIcon />
    },
    {
      path: '/results',
      displayName: 'Results',
      icon: <RuleRoundedIcon />
    },
  ]

  const PageLink = ({ displayName, path, icon }) => {
    return (
      <ListItem
        button
        sx={{
          color: window.location.pathname === path
            ? 'primary.main' : 'primary.secondary',
        }}
        onClick={() => navigate(path)}
      >
        <ListItemIcon
          sx={{
            color: window.location.pathname === path
              ? 'primary.main' : 'primary.secondary',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={displayName} />
      </ListItem>
    )
  }

  const renderTaskLinks = () => {
    if (!state.profile) return
    return pages.map((p,idx) => (
      <PageLink
        key={`nav-tasks-${idx}`}
        path={p.path}
        displayName={p.displayName}
        icon={p.icon}
      />
    ))
  }

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Divider />
      <List sx={{ pt: 3 }}>
        {renderTaskLinks()}
      </List>
    </Drawer>
  )
}
