import React, {
  useContext,
  useReducer
} from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'

import PageWrapper from './page_wrapper'

import api from '../../util/api'
import { AppContext } from '../../App'

const taskStatusMsg = {
  0: 'NOT STARTED',
  1: 'IN PROGRESS',
  2: 'FAILED',
  3: 'SUCCESS',
}

const style = {
  container: {
    width: '100%',
    bgcolor: 'background.default',
    p: 3,
    '& button': { px: 2, py: 1 }
  },
  divider: {
    height: '.5rem',
    border: 'none'
  },
  form: {
    bgcolor: 'background.paper',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    textAlign: 'justify',
    '& .MuiInputBase-root': {
      mr: 2
    }
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '6rem'
  }
}

const initialState = {
  form: {
    'first_name': {
      value: '',
      required: true,
      max: 15
    },
    'middle_name': {
      value: '',
      required: false,
      max: 15
    },
    'last_name': {
      value: '',
      required: true,
      max: 25
    },
    'suffix_name': {
      value: '',
      required: false,
      max: 4
    },
    'city': {
      value: '',
      required: true,
    },
    'street_name': {
      value: '',
      required: true,
      max: 26
    },
    'street_type': {
      value: '',
      required: true,
      max: 10
    },
    'house_number': {
      value: '',
      required: false,
    },
    'apartment_number': {
      value: '',
      required: false,
    },
    'quadrant': {
      value: '',
      required: false,
      max: 2
    },
    'postal_code': {
      value: '',
      required: true,
      max: 9
    },
    'state': {
      value: '',
      required: true,
      max: 2
    },
    'dob': {
      value: '',
      required: false,
      max: 10
    },
    'ssn': {
      value: '',
      required: true,
      min: 4,
      max: 11
    },
    'phone': {
      value: '',
      required: false,
      max: 10
    },
  },
  formErrors: {}
}

const reducer = (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      newState[action.state] = action.value
      return newState
    case 'reset':
      return initialState
    default:
      return newState
  }
}

export default function TaskCB({ nextPage }) {
  const [taskState, taskDispatch] = useReducer(reducer, initialState)
  const {
    state,
    dispatch,
    fetchProfile,
    showAlert
  } = useContext(AppContext)
  const navigate = useNavigate()

  const validateForm = () => {
    const errors = {}
    try {
      for (const [k, v] of Object.entries(taskState.form)) {
        if (v.required && !v.value) {
          errors[k] = 'required'
          continue
        }
        if (!v.value) continue
        if (v.min && (v.value?.length < v.min)) {
          errors[k] = `min ${v.min} chars`
          continue
        }
        if (v.max && (v.value?.length > v.max)) {
          errors[k] = `max ${v.max} chars`
          continue
        }
      }
      taskDispatch({ type: 'set', state: 'formErrors', value: errors })
      return errors
    }
    catch {
      taskDispatch({ type: 'set', state: 'formErrors', value: errors })
      return errors
    }
  }

  const verify = async () => {
    dispatch({ type: 'show_loading' })
    try {
      const errors = validateForm()
      if (Object.keys(errors)?.length) {
        return
      }
      const data = Object.entries(taskState.form)
        .reduce((acc, [k, v]) => {
          acc[k] = v.value
          return acc
        }, {})
      const resp = await api.verifyReports(state.webJWT, data)
      if (resp.status !== 200) {
        showAlert('There was a problem with your request.')
        return
      }
      fetchProfile(state.webJWT)
    }
    catch (e) {
      console.log(e)
      showAlert('There was a problem with your request.')
    }
    finally {
      dispatch({ type: 'close_loading' })
    }
  }

  const onChangeForm = (field, value) => {
    const form = { ...taskState.form }
    form[field].value = value
    taskDispatch({
      type: 'set',
      state: 'form',
      value: form
    })
  }

  const showVerify = () => {
    if (
      state.profile.poc['task_4'] === 3 ||
      state.profile.poc['task_5'] === 3 ||
      state.profile.poc['task_6'] === 3
    ) {
      return (
        <LoadingButton
          size='small'
          variant='contained'
          onClick={() => navigate(nextPage)}
        >
          Go to results
        </LoadingButton>
      )
    }
    return (
      <>
        <Box sx={style.form}>
          <Box sx={style.formRow}>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                First Name{form['first_name'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['first_name'].required}
                id='cb-form-first-name'
                size='small'
                value={form['first_name'].value}
                onChange={e => onChangeForm('first_name', e.target.value)}
                error={errors['first_name'] ? true : false}
                helperText={errors['first_name']}
                sx={{ width: 180 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Last Name{form['last_name'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['last_name'].required}
                id='cb-form-last-name'
                size='small'
                value={form['last_name'].value}
                onChange={e => onChangeForm('last_name', e.target.value)}
                error={errors['last_name'] ? true : false}
                helperText={errors['last_name']}
                sx={{ width: 180 }}
              />
            </Box>
          </Box>

          <Divider sx={style.divider} />

          <Box sx={style.formRow}>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Middle Name{form['middle_name'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['middle_name'].required}
                id='cb-form-middle-name'
                size='small'
                value={form['middle_name'].value}
                onChange={e => onChangeForm('middle_name', e.target.value)}
                error={errors['middle_name'] ? true : false}
                helperText={errors['middle_name']}
                sx={{ width: 180 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Suffix{form['suffix_name'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['suffix_name'].required}
                id='cb-form-suffix-name'
                size='small'
                value={form['suffix_name'].value}
                onChange={e => onChangeForm('suffix_name', e.target.value)}
                error={errors['suffix_name'] ? true : false}
                helperText={errors['suffix_name']}
                sx={{ width: 100 }}
              />
            </Box>
          </Box>

          <Divider sx={style.divider} />

          <Box sx={style.formRow}>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                House No.{form['house_number'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['house_number'].required}
                id='cb-form-house-number'
                size='small'
                value={form['house_number'].value}
                onChange={e => onChangeForm('house_number', e.target.value)}
                error={errors['house_number'] ? true : false}
                helperText={errors['house_number']}
                sx={{ width: 120 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Street Name{form['street_name'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['street_name'].required}
                id='cb-form-street-name'
                size='small'
                value={form['street_name'].value}
                onChange={e => onChangeForm('street_name', e.target.value)}
                error={errors['street_name'] ? true : false}
                helperText={errors['street_name']}
                sx={{ width: 220 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                St. Type{form['street_type'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['street_type'].required}
                id='cb-form-street-type'
                size='small'
                value={form['street_type'].value}
                onChange={e => onChangeForm('street_type', e.target.value)}
                error={errors['street_type'] ? true : false}
                helperText={errors['street_type']}
                sx={{ width: 100 }}
              />
            </Box>
          </Box>

          <Divider sx={style.divider} />

          <Box sx={style.formRow}>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Apartment No.{form['apartment_number'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['apartment_number'].required}
                id='cb-form-apartment-number'
                size='small'
                value={form['apartment_number'].value}
                onChange={e => onChangeForm('apartment_number', e.target.value)}
                error={errors['apartment_number'] ? true : false}
                helperText={errors['apartment_number']}
                sx={{ width: 140 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Quadrant{form['quadrant'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['quadrant'].required}
                id='cb-form-quadrant'
                size='small'
                value={form['quadrant'].value}
                onChange={e => onChangeForm('quadrant', e.target.value)}
                error={errors['quadrant'] ? true : false}
                helperText={errors['quadrant']}
                sx={{ width: 140 }}
              />
            </Box>
          </Box>

          <Divider sx={style.divider} />

          <Box sx={style.formRow}>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                City{form['city'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['city'].required}
                id='cb-form-city'
                size='small'
                value={form['city'].value}
                onChange={e => onChangeForm('city', e.target.value)}
                error={errors['city'] ? true : false}
                helperText={errors['city']}
                sx={{ width: 200 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                State{form['state'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['state'].required}
                id='cb-form-state'
                size='small'
                value={form['state'].value}
                onChange={e => onChangeForm('state', e.target.value)}
                error={errors['state'] ? true : false}
                helperText={errors['state']}
                sx={{ width: 140 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Postal{form['postal_code'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['postal_code'].required}
                id='cb-form-postal_code'
                size='small'
                value={form['postal_code'].value}
                onChange={e => onChangeForm('postal_code', e.target.value)}
                error={errors['postal_code'] ? true : false}
                helperText={errors['postal_code']}
                sx={{ width: 160 }}
              />
            </Box>
          </Box>

          <Divider sx={style.divider} />

          <Box sx={style.formRow}>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                DOB{form['dob'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['dob'].required}
                id='cb-form-dob'
                size='small'
                value={form['dob'].value}
                onChange={e => onChangeForm('dob', e.target.value)}
                error={errors['dob'] ? true : false}
                helperText={errors['dob']}
                sx={{ width: 160 }}
                placeholder='yyyy-mm-dd'
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                SSN{form['ssn'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['ssn'].required}
                id='cb-form-ssn'
                size='small'
                value={form['ssn'].value}
                onChange={e => onChangeForm('ssn', e.target.value)}
                error={errors['ssn'] ? true : false}
                helperText={errors['ssn']}
                sx={{ width: 160 }}
              />
            </Box>
            <Box sx={style.formField}>
              <Typography variant='subtitle2' color='text.secondary'>
                Phone{form['phone'].required ? ' - Required' : ''}
              </Typography>
              <TextField
                required={form['phone'].required}
                id='cb-form-phone'
                size='small'
                value={form['phone'].value}
                onChange={e => onChangeForm('phone', e.target.value)}
                error={errors['phone'] ? true : false}
                helperText={errors['phone']}
                sx={{ width: 160 }}
              />
            </Box>
          </Box>

          <Divider sx={{ ...style.divider, height: '2rem' }} />

        </Box>
        <Box>
          <LoadingButton
            size='small'
            variant='contained'
            onClick={verify}
          >
            Verify and complete tasks 4 to 6
          </LoadingButton>
        </Box>
      </>
    )
  }

  const getTaskStatus = () => {
    if (state.profLoading) {
      return (
        <Box
          sx={{
            p: 2,
            minHeight: '6rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )
    }
    if (!state.profile.poc) return
    return (
      <Box sx={style.statusContainer}>
        <Typography variant='h6' color='text.secondary'>
          Task 4 (EFX) = {taskStatusMsg[state.profile.poc['task_4']]}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          Task 5 (TU) = {taskStatusMsg[state.profile.poc['task_5']]}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          Task 6 (EXP) = {taskStatusMsg[state.profile.poc['task_6']]}
        </Typography>
      </Box>
    )
  }

  let form = taskState.form
  let errors = taskState.formErrors

  return (
    <PageWrapper>
      <Box component='main' sx={style.container}>
        <Toolbar />

        {getTaskStatus()}

        <Divider sx={{ ...style.divider, height: '1rem' }} />

        {showVerify()}
      </Box>
    </PageWrapper>
  )
}
