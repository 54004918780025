import * as React from 'react'
import Box from '@mui/material/Box'
import Header from '../header/header'
import Nav from '../nav/nav'

export default function PageWrapper({ children }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Nav />
      {children}
    </Box>
  )
}
