import React, {
  useState,
  useContext,
  useEffect
} from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'

import { AppContext } from '../../App'
import api from '../../util/api'
import { formatDateY } from '../../util/date'
import { decodeJWT } from '../../util/jwt'

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    textAlign: 'justify'
  },
  divider: {
    height: '1rem',
    border: 'none'
  },
  form: {
    bgcolor: 'background.paper',
    '& .MuiTextField-root': { mr: 2 },
    display: 'flex',
    pt: 2,
    pb: 2,
  }
}

export default function PersonForm() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)
  const [decodedLoginJWT, setDecodedLoginJWT] = useState(false)

  const {
    state,
    fetchToken,
    showAlert
  } = useContext(AppContext)

  useEffect(() => {
    if (state.loginJWT) {
      const decoded = decodeJWT(state.loginJWT)
      setDecodedLoginJWT(decoded)
    }
  }, [state.loginJWT])

  const handleContinue = async () => {
    setLoading(true)
    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        token: state.loginJWT
      }
      const resp = await api.signup(data)
      if (resp.status === 201) {
        fetchToken(state.loginJWT)
        return
      }
      showAlert('There was a problem with your request, please try again.')
    }
    catch (e) {
      showAlert('There was a problem with your request, please try again.')
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={style.container}>
      <Typography variant='h6' component='h2'>
        Personal Information
      </Typography>
      <Typography variant='h8'>
        Date signed: {formatDateY(new Date())}
      </Typography>
      <Typography variant='h12' color='text.secondary'>
        <p>
          I understand that by clicking ACCEPT and typing my name I am
          providing my consent electronically and that it has the same force
          and effect as if I was signing in person on paper.
        </p>
        <p>
          The information in this consent signature will be recorded.
        </p>
      </Typography>

      <Typography variant='body1' color='text.secondary' component='div'>
        Please use your real name for successful completion.
      </Typography>

      <Divider sx={style.divider} />

      <Typography variant='body1' color='text.secondary'>
        Email: {decodedLoginJWT.email}
      </Typography>

      <Box component='form' sx={style.form}>
        <TextField
          required
          id='person-form-first-name'
          label='First Name'
          size='small'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          required
          id='person-form-last-name'
          label='Last Name'
          size='small'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>

      <Divider sx={style.divider} />

      <Box sx={{ display: 'flex' }}>
        <LoadingButton
          variant='contained'
          onClick={handleContinue}
          disabled={!firstName || !lastName || lastName.length < 2}
          loading={loading}
        >
          ACCEPT
        </LoadingButton>
      </Box>
    </Box>
  )
}