import React, { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { AppContext } from '../../App'
// import logo from '../../images/bitdoor_logo.png'

export default function Header() {
  const { handleLogout } = useContext(AppContext)
  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#fff',
        display: 'flex'
      }}
    >
      <Toolbar>
        <Typography
          variant='h5'
          noWrap
          component='div'
          color='primary'
          sx={{ flexGrow: 1, display: 'flex' }}
        >
          {/* <img style={{ height: '50px' }} src={logo} alt='logo' /> */}
        </Typography>
        <Button
          variant='outlined'
          size='small'
          onClick={handleLogout}
        >
          Log out
        </Button>
      </Toolbar>
    </AppBar>
  )
}
