import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'
import api from '../../util/api'
import logo from '../../images/logoPD.png'

export default function Login() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const { webJWT, showAlert } = useContext(AppContext)

  useEffect(() => {
    if (webJWT) {
      navigate('/')
    }
  }, [webJWT, navigate])

  const handleLogin = async () => {
    if (!email) return
    const res = await api.login(email)
    if (!res) {
      showAlert('There was a problem with your request.')
      return
    }
    if (res.status === 200) {
      showAlert('Please check your email for login link.')
    } else {
      showAlert(res.data.message
        ? res.data.message
        : 'There was a problem with your request.'
      )
    }
  }

  return (
    <Box
      component='main'
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
      bgcolor='#212121'
    >
      <Box
        component='div'
        bgcolor='#fff'
        padding='2rem'
        borderRadius='.25rem'
        minHeight='20rem'
        minWidth='18rem'
        display='flex'
        justifyContent='center'
        flexDirection='column'
      >
        <Typography
          variant='h4'
          color='text.secondary'
          component='div'
          m='1rem auto 3rem auto'
        >
          <img src={logo} alt='logo' />
        </Typography>
        <TextField
          placeholder='Email'
          variant='outlined'
          type='email'
          size='small'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant='contained'
          sx={{ m: '1rem auto' }}
          onClick={handleLogin}
          disabled={email ? false : true}
          fullWidth
        >
          Login
        </Button>
        <Typography
          variant='subtitle2'
          color='text.secondary'
          sx={{ m: 1 }}
        >
          We will email you instructions to log in
        </Typography>
      </Box>
    </Box>
  )
}
