import React, { useState, useEffect, useContext } from 'react'

import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'

import PageWrapper from './page_wrapper'
import PersonForm from './person_form'

export default function Home() {
  const [scrolledBottom, setScrolledBottom] = useState(false)
  const [loading, setLoading] = useState(false)
  const [personForm, setPersonForm] = useState(false)
  const navigate = useNavigate()

  const {
    handleLogout,
    state
  } = useContext(AppContext)

  useEffect(() => {
    if (!state.loginJWT && !state.webJWT) {
      navigate('/login')
    }
  }, [state.loginJWT, state.webJWT, navigate])

  const showConsentForm = () => {
    if (state.profLoading) {
      return (
        <Box
          sx={{
            p: 2,
            minHeight: '10rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )
    }

    if (state.profile) {
      return (
        <>
          <Typography variant='body1' color='text.secondary' noWrap component='div'>
            You have already given your consent for this project.
          </Typography>
          <Typography variant='body1' color='text.secondary' noWrap component='div'>
            Do you want to opt-out? Contact us at &nbsp;
            <Link href='https://pentadatainc.com/contact-us' target='_blank'>
              https://pentadatainc.com/contact-us
            </Link>
          </Typography>
          <br />
          <LoadingButton
            variant='contained'
            onClick={() => {
              setLoading(true)
              setTimeout(() => {
                setLoading(false)
                navigate('/1')
              }, [1000])
            }}
            disabled={false}
            loading={loading}
          >
            Next step
          </LoadingButton>

        </>
      )
    }

    if (personForm) {
      return (
        <PersonForm />
      )
    }

    return (
      <Box width='100%'>
        <Typography variant='body1' color='text.secondary' component='div'>
          Thank you for your interest in participating in this Study. Review carefully the terms below, then decide if you want to continue the task, or to opt-out.
        </Typography>
        <br />
        <Box
          sx={{
            maxHeight: '55vh',
            overflowY: 'scroll',
            border: '2px solid black',
            borderRadius: '0.25rem',
            padding: '0.5rem',
            width: '100%',
            maxWidth: '100%',
            fontFamily: 'Times New Roman'
          }}
          onScroll={e => {
            const delta = 20;
            console.log('=====');
            console.log(e.target.clientHeight);
            console.log(e.target.scrollHeight);
            console.log(e.target.scrollTop);
            const diff = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
            console.log(diff);
            if (diff > -1 * delta && diff < delta) {
              setScrolledBottom(true)
            } else {
              setScrolledBottom(false)
            }
          }}
          onChange={() => console.log(scrolledBottom)}
        >
          <Typography variant='h7' align='center' color='text.secondary' component='div'>
            <b>STUDY INFORMED CONSENT FORM</b>
            <br />
            <b>Study Organizer</b>: Pentadata, Inc. - support@pentadatainc.com
            <br />
            <b>Study Sponsor</b>: Vantage Score Solutions, LLC
          </Typography>
          <br />
          <Typography variant='body1' color='text.secondary' component='div'>
            <p>If you agree with the terms, then <b>you will be asked to connect a bank account and to verify your credit bureaus profile</b>.</p>
            Your submission will be approved if:
              <ol>
                <li>You connect successfully the same bank account three times (Task 1, 2 and 3).</li>
                <li>Your credit file are NOT frozen and successfully verified (Task 4).</li>
              </ol>
          </Typography>
          <br />
          <Typography variant='body' color='text.secondary' component='div'>
            <b>YOUR CREDIT SCORE WILL NOT BE AFFECTED BY THIS STUDY.</b>
          </Typography>
          <br />
          <Typography variant='body' color='text.secondary' component='div'>
            <b>IF YOUR CREDIT FILES ARE FROZEN, YOU HAVE TO UNFREEZE THEM AT EACH CREDIT BUREAU'S WEBSITE BEFORE PARTICIPATING IN THIS STUDY.</b>
          </Typography>
          <br />
          <Typography variant='body' color='text.secondary' component='div'>
              <b>Your decision to participate in this Study is completely voluntary and you may decline to participate or stop
              or withdraw your participation at any time.</b> You do not have to sign this Consent, but to participate in this Study,
              you must sign this Consent.
          </Typography>
          <br /> <br />
          <Typography variant='body' color='text.secondary' component='div'>
              <b>WHAT WILL HAPPEN IF I TAKE PART IN THIS STUDY?</b>
          </Typography>
          <br />
          <Typography variant='body' color='text.secondary' component='div'>
            The Study procedures is listed below.
            <ol>
              <li>You will receive an invitation to participate in the Study. If you choose to participate, you will be presented with this Consent.</li>
              <li>After Consenting to this Study, you will be prompted to complete Task 1, 2, 3 and 4.</li>
              <li>Task 1, 2 and 3 authorize Pentadata to connect your financial bank accounts and view up to two years of payment history.</li>
              <li>Task 4 authorizes Pentadata to view your credit files at Equifax, TransUnion and Experian. This process does NOT affect your credit score.</li>
              <li>Pentadata will transport your financial data to Vantage Score, the Sponsor of the Study.</li>
              <li>Vantage Score will analyze your data in order to develop a better and more inclusive credit scoring process.</li>
            </ol>
          </Typography>
          <br /> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            <b>WHO CAN PARTICIPATE IN THIS STUDY?</b>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            Before entering this Study, you must meet all of the following criteria:
            <ul>
              <li>You must be a US citizen.</li>
              <li>You must be at least 18 years old.</li>
              <li>You must have a credit or checking account.</li>
              <li>Your credit files at Equifax, TransUnion and Experian must be available.</li>
            </ul>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            <b>WILL I RECEIVE COMPENSATION FOR TAKING PART IN THIS STUDY?</b>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            You will receive a reward of up to $100.00 for your participation in this Study, provided after you have successfully enrolled
            in the Study and your Study Data has been collected.
          </Typography> <br /> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            <b>HOW CAN I WITHDRAW FROM THIS STUDY?</b>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            You may withdraw from this Study at any time, for any reason, without penalty by contacting a member of the Study Organizer whose contact information is at the top of this Consent.
          </Typography> <br /> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            <b><u>CONSENT</u></b>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            By signing this Consent, I acknowledge and agree:
            <ol>
              <li>I have carefully read this Consent, which is written in English, and English is a language that I read and understand.</li>
              <li>I understand this Consent and have received answers to my questions.</li>
              <li>I am voluntarily signing this Consent indicating that I consent to participate in this Study.</li>
              <li>I agree that I will keep confidential all information disclosed to me during the Study.</li>
              <li>I agree to the collection, use, sharing, disclosure, transfer, including transfer to other countries,
                  and maintenance of my Study Data (including Coded Study Data) as described in this Consent.</li>
              <li>I understand that I can withdraw from the Study at any time.</li>
              <li>I understand that I may ask for a copy of this Consent.</li>
            </ol>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            If this is an electronic consent I understand that by clicking accept or typing my name and
            the date below I am providing my consent electronically and that it has the same force and effect as
            if I was signing in person on paper.
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            '& button': { ml: 3, minWidth: '10rem' }
          }}
        >
          <Button
            variant='contained'
            onClick={() => handleLogout()}
          >
            No, log me out
          </Button>
          <LoadingButton
            variant='contained'
            onClick={() => {
              setLoading(true)
              setTimeout(() => {
                setLoading(false)
                setPersonForm(true)
              }, [1000])
            }}
            disabled={!scrolledBottom}
            loading={loading}
          >
            OK, I consent
          </LoadingButton>
        </Box>
      </Box>
    )
  }

  return (
    <PageWrapper>
      <Box
        component='main'
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        <Typography variant='h4' color='text.secondary' noWrap component='div'>
          Project Overview and Consent
        </Typography>
        <br />
        {showConsentForm()}
      </Box>
    </PageWrapper>
  )
}
