import React, { useState, useContext } from 'react'
import urljoin from 'url-join'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import LoadingButton from '@mui/lab/LoadingButton'

import { AppContext } from '../../App'
import { FRONTEND_URL } from '../../lib/config'
import bankList from '../../lib/bankList.json'
import api from '../../util/api'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

export default function BankSelect({ open, setOpen, aggId }) {
  const [selectedBank, setSelectedBank] = useState({})
  const { state, dispatch, showAlert } = useContext(AppContext)

  const handleClose = () => {
    setSelectedBank({})
    setOpen(false)
  }

  const getBankLink = async () => {
    if (!Object.keys(selectedBank).length) return
    dispatch({ type: 'show_loading' })
    try {
      const iid = Object.keys(selectedBank)[0]
      const redirectUri = urljoin(FRONTEND_URL, window.location.pathname)
      const data = {
        consumer: {
          first_name: state.profile.consumer.first_name,
          last_name: state.profile.consumer.last_name
        },
        institution_id: iid,
        aggregator_id: aggId,
        redirect_uri: redirectUri,
        oauth: false
      }
      const resp = await api.bankLink(state.webJWT, data)
      if (resp.data.url) {
        window.open(resp.data.url, '_blank')
        handleClose()
        return
      }
      showAlert('There was a problem with your request.')
    }
    catch (e) {
      console.log(e)
      showAlert('There was a problem with your request.')
    }
    finally {
      dispatch({ type: 'close_loading' })
    }
  }

  const handleSelect = (bank) => {
    const selected = {}
    selected[bank] = bankList[bank]
    setSelectedBank(selected)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          Select Bank
        </Typography>
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            height: 400,
            overflow: 'scroll'
          }}
        >
          <List
            component='div'
            aria-label='main mailbox folders'
            sx={{ m: '1rem 0' }}
          >
            {Object.keys(bankList).map((b, idx) => (
              <ListItemButton
                key={`bank-list-${idx}`}
                selected={selectedBank[b] === bankList[b]}
                onClick={() => handleSelect(b)}
              >
                <ListItemText primary={bankList[b].name} />
              </ListItemButton>
            ))}
          </List>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <LoadingButton
            size='small'
            variant='contained'
            onClick={getBankLink}
            disabled={!Object.keys(selectedBank).length}
          >
            CONTINUE
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}
