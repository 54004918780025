import React, { useContext, } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import CircularProgress from '@mui/material/CircularProgress'

import PageWrapper from './page_wrapper'

import { AppContext } from '../../App'

const taskStatusMsg = {
  0: 'NOT STARTED',
  1: 'IN PROGRESS',
  2: 'FAILED',
  3: 'SUCCESS',
}

const style = {
  container: {
    width: '100%',
    bgcolor: 'background.default',
    p: 3,
    '& button': { px: 2, py: 1 }
  },
  divider: {
    height: '.5rem',
    border: 'none'
  },
}

export default function Results() {
  const { state } = useContext(AppContext)

  const getTaskStatus = () => {
    if (state.profLoading) {
      return (
        <Box
          sx={{
            p: 2,
            minHeight: '6rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )
    }
    if (!state.profile.poc) return
    return (
      <Box sx={style.statusContainer}>
        <Typography variant='h6' color='text.secondary'>
          Task 1 = {taskStatusMsg[state.profile.poc['task_1']]}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          Task 2 = {taskStatusMsg[state.profile.poc['task_2']]}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          Task 3 = {taskStatusMsg[state.profile.poc['task_3']]}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          Task 4 = {taskStatusMsg[state.profile.poc['task_4']]}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          Task 5 = {taskStatusMsg[state.profile.poc['task_5']]}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          Task 6 = {taskStatusMsg[state.profile.poc['task_6']]}
        </Typography>
      </Box>
    )
  }

  return (
    <PageWrapper>
      <Box component='main' sx={style.container}>
        <Toolbar />
        {getTaskStatus()}
      </Box>
    </PageWrapper>
  )
}
