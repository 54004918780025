import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'

import PageWrapper from './page_wrapper'
import BankSelect from '../modal/bank_select'
import AccountsTable from './accounts_table'

import api from '../../util/api'
import { AppContext } from '../../App'

const taskStatusMsg = {
  0: 'This task has not started',
  1: 'This task is in progress',
  2: 'This task was unsuccessful',
  3: 'This task is COMPLETE',
}

export default function Task({ aggId, status, nextPage }) {
  const {
    state,
    dispatch,
    fetchProfile,
    fetchAccounts
  } = useContext(AppContext)
  const [openBankSelect, setOpenBankSelect] = useState(false)
  const [aggAccounts, setAggAccounts] = useState([])
  const navigate = useNavigate()

  const verify = async () => {
    dispatch({ type: 'show_loading' })
    try {
      const accIds = aggAccounts.map(a => a.id)
      const data = { account_ids: accIds }
      const resp = await api.verify(aggId, state.webJWT, data)
      if (resp.status === 200) {
        fetchProfile(state.webJWT)
      }
    }
    catch (e) {
      console.log(e)
    }
    finally {
      dispatch({ type: 'close_loading' })
    }
  }

  useEffect(() => {
    if (state.accounts?.length) {
      const filtered = state.accounts.filter(acc => acc.aggregator === aggId)
      setAggAccounts(filtered)
    }
  }, [state.accounts, aggId])

  const showAccountsTable = () => {
    if (state.accsLoading) {
      return (
        <Box
          sx={{
            p: 2,
            minHeight: '10rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )
    }

    if (!aggAccounts.length) {
      return (
        <Box>
          <Typography color='text.secondary' sx={{ my: 2 }}>
            No accounts found, authenticated bank accounts will display here.
          </Typography>
          <Typography color='text.secondary' sx={{ my: 2 }}>
            Come back to this page and refresh it after connecting your bank accounts.
          </Typography>

          <Button
            size='small'
            variant='contained'
            onClick={() => setOpenBankSelect(true)}
          >
            Add accounts
          </Button>
          <Button
            size='small'
            variant='contained'
            onClick={() => fetchAccounts(state.webJWT)}
            sx={{ ml: 1 }}
          >
            Refresh Accounts List
          </Button>
        </Box>
      )
    }
    console.log('Showing accounts.')
    return (
      <Box
        sx={{
          minHeight: '1rem',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <AccountsTable data={aggAccounts} />
      </Box>
    )
  }

  const showVerify = () => {
    if (!aggAccounts.length) return
    const task_status = state.profile.poc[status] 
    if (task_status === 1 || task_status === 2 || task_status === 3) {
      return (
        <Box>
          <LoadingButton
            size='small'
            variant='contained'
            onClick={() => navigate(nextPage)}
          >
            Go to next task
          </LoadingButton>
        </Box>
      )
    }
    return (
      <Box>
        <LoadingButton
          size='small'
          variant='contained'
          onClick={verify}
        >
          Verify and complete task
        </LoadingButton>
      </Box>
    )
  }

  const modals = (
    <>
      <BankSelect
        open={openBankSelect}
        setOpen={setOpenBankSelect}
        aggId={aggId}
      />
    </>
  )

  const getTaskStatus = () => {
    if (!state.profile.poc) return
    if (state.profile.poc[status]) {
      return taskStatusMsg[state.profile.poc[status]]
    }
    return taskStatusMsg[0]
  }

  return (
    <PageWrapper>
      <Box
        component='main'
        sx={{
          width: '100%',
          bgcolor: 'background.default',
          p: 3,
          '& button': { px: 2, py: 1 }
        }}
      >
        <Toolbar />
        <Box>
          <Typography variant='h6' color='text.secondary' component='div'>
            {getTaskStatus()}
          </Typography>
        </Box>
        {showAccountsTable()}
        {showVerify()}
      </Box>
      {modals}
    </PageWrapper>
  )
}
